import { Type } from '@/components/common'
import { Campaign } from '@/services/api/campaign'
import useSiteAssets from '@/services/hooks/useSiteAssets'

interface Props {
  campaign: Campaign
  className?: string
}

const TimeRemaining: React.FC<Props> = ({ campaign, className }) => {
  const { timeRemainingValue, timeRemainingDimension } = campaign.timeRemaining
  const clarificationSymbol = campaign.isSellingFast ? '†' : '*'
  const siteAssets = useSiteAssets()

  return (
    <Type
      as="p"
      variant="paragraph-lg"
      className={className}
      data-testid="TimeRemaining"
    >
      {timeRemainingValue} {timeRemainingDimension}{' '}
      {siteAssets?.timeRemainingDescriptor}
      {clarificationSymbol}
    </Type>
  )
}

export default TimeRemaining
